<template>
  <b-container class='home-page text-center mb-4' style="box-shadow: none; background-color: inherit;">
    <b-row>
      <b-col
        lg='6'
        md='6'
        sm='6'
        >
        <div style="margin-bottom: 10px; width: 100%; float:left; border-radius: 7px; box-shadow:  0px 0px 3px 0px rgba(0,0,0,.3); background-color: #ffffff; min-height: 42vh; ">
          <h3 style="color: #037AFF; margin-top: 2vh;" >{{ $t('home.state-header') }}</h3>
          <div class='home-page__title clearfix'>
            <p class='font-weight-bold'>{{ $t('home.title') }}</p>
          </div>
          <div class="home-page__form pr-5 pl-5">
            <p class='font-weight-bold mb-0'>{{ $t('home.state-text1') }}</p>
            <p class='mb-0'>{{ $t('home.state-text2') }}</p>
            <a href="https://servizz.gov.mt/en/Pages/Identity_-Citizenship-and-Immigration/National-Identity/Identity-Management/WEB082/default.aspx">{{ $t('home.link-description') }}</a>
            <!-- <p class='font-weight-bold'>{{ $t('home.text2') }}</p> -->
          </div>
          <div>
            <b-button style="margin-bottom: 10px;" class="mt-app__buttons help-guide" type="submit" variant="info" :disabled="isRedirected">
              <span class="home-page__button-text"><a style="color: #ffffff !important;" href="./leaflets/state-help-guide-en.pdf" target="_blank">{{ $t('home.help-guide-english') }}</a></span>
            </b-button>
            &nbsp;
            <b-button style="margin-bottom: 10px;" class="mt-app__buttons help-guide" type="submit" variant="info" :disabled="isRedirected">
              <span class="home-page__button-text"><a style="color: #ffffff !important;" href="./leaflets/state-help-guide-mt.pdf" target="_blank">{{ $t('home.help-guide-maltese') }}</a></span>
            </b-button>
          </div>
          <b-button style="margin-bottom: 10px;" @click="handleSSOLogin('state')"  type="submit" class="mt-app__buttons" variant="primary" :disabled="isRedirected">
            <span v-if="isRedirected" class="home-page__button-loading"></span>
            <span v-else class="home-page__button-text">{{ $t('home.button') }}</span>
          </b-button>
        </div>
      </b-col>
      <b-col
        lg='6'
        md='6'
        sm='6'
        >
        <div style="margin-bottom: 10px; width: 100%; float:right; border-radius: 7px; box-shadow:  0px 0px 3px 0px rgba(0,0,0,.3); background-color: #ffffff; min-height: 42vh;">
          <h3 style="color: green; margin-top: 2vh;">{{ $t('home.non-state-header') }}</h3>
          <div class='home-page__title clearfix'>
            <p class='font-weight-bold'>{{ $t('home.title') }}</p>
          </div>
          <div class="home-page__form pr-5 pl-5">
            <p class='font-weight-bold mb-0'>{{ $t('home.non-state-text1') }}</p>
            <p class='mb-0'>{{ $t('home.non-state-text2') }}</p>
            <a href="https://servizz.gov.mt/en/Pages/Identity_-Citizenship-and-Immigration/National-Identity/Identity-Management/WEB082/default.aspx">{{ $t('home.link-description') }}</a>
            <!-- <p class='font-weight-bold'>{{ $t('home.text2') }}</p> -->
          </div>
          <div>
            <b-button style="margin-bottom: 10px;" class="mt-app__buttons help-guide" type="submit" variant="info" :disabled="isRedirected">
              <span class="home-page__button-text"><a style="color: #ffffff !important;" href="./leaflets/non-state-help-guide-en.pdf" target="_blank">{{ $t('home.help-guide-english') }}</a></span>
            </b-button>
            &nbsp;
            <b-button style="margin-bottom: 10px;" class="mt-app__buttons help-guide" type="submit" variant="info" :disabled="isRedirected">
              <span class="home-page__button-text"><a style="color: #ffffff !important;" href="./leaflets/non-state-help-guide-mt.pdf" target="_blank">{{ $t('home.help-guide-maltese') }}</a></span>
            </b-button>
          </div>
          <b-button style="margin-bottom: 10px;" @click="handleSSOLogin('non-state')"  type="submit" class="mt-app__buttons" variant="success" :disabled="isRedirected">
            <span v-if="isRedirected" class="home-page__button-loading"></span>
            <span v-else class="home-page__button-text">{{ $t('home.button') }}</span>
          </b-button>
        </div>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import { mapState, mapMutations, mapActions } from 'vuex'
import { baseURL, path } from '@/axios'
import { errorPrompt } from '@/prompts'
import storage from '@/storage'

export default {
  name: 'home',
  data () {
    return {
      isRedirected: false
    }
  },
  computed: {
    ...mapState({
      resubmissionTokenHasError: state => state['resubmissionTokenHasError']
    })
  },
  methods: {
    ...mapMutations({
      setErrorWithResubmissionToken: 'SET_ERROR_WITH_RESUBMISSION_TOKEN'
    }),
    ...mapActions({
      getUserData: 'getUserData'
    }),
    handleSSOLogin (schoolsDomain) {
      localStorage.setItem('schools', schoolsDomain)
      window.location.href = baseURL + path.citizensApp
    },
    handleSSOLoginSuccess () {
      let schools = localStorage.getItem('schools')
      if (schools === 'state') {
        this.$router.push({ name: 'welcome' })
      } else if (schools === 'non-state') {
        this.$router.push({ name: 'form-non-state' })
      } else {
        this.$router.push({ name: 'error' })
      }
    },
    handleSSOLoginError (error) {
      this.isRedirected = false
      const errorOptions = {
        confirmButtonText: this.$i18n.t('swal.ok-button')
      }
      if (error === 'noChildren') {
        return this.$router.push({ name: 'welcome' })
        // return errorPrompt(this.$i18n.t('swal.login.no-children'), errorOptions)
      } else if (error.response.status === 403) {
        this.$router.push({ name: 'welcome' })
        return errorPrompt(this.$i18n.t('swal.main-form.login-error-403'), errorOptions)
      } else if (error.response.status === 406) {
        this.$router.push({ name: 'home' })
        return errorPrompt(this.$i18n.t('swal.main-form.reject-error-406-periodIsOver'), errorOptions)
      } else if (error.response.status === 417) {
        this.$router.push({ name: 'home' })
        return errorPrompt(this.$i18n.t('swal.main-form.reject-error-417-nonStatePeriodIsOver'), errorOptions)
      } else if (error.response.status === 429) {
        return errorPrompt(this.$i18n.t('swal.main-form.too-many-requests-429'), errorOptions)
      } else if (error.response.status === 412) {
        return errorPrompt(this.$i18n.t('swal.resubmission-form.reject-error-412-studentInactive'), errorOptions)
      }
      errorPrompt(this.$i18n.t('swal.login.error'), errorOptions)
    }
  },
  created () {
    this.isRedirected = 'token' in this.$route.query
    if (this.isRedirected) {
      const token = this.$route.query.token || storage.get.token()
      let schools = localStorage.getItem('schools')
      const payload = { 'token': token, 'schools': schools }
      this.getUserData(payload)
        .then(() => this.handleSSOLoginSuccess())
        .catch((error) => this.handleSSOLoginError(error))
    } else if (storage.get.token()) {
      this.handleSSOLogin()
    } else if (storage.get.resubmissionToken()) { // if user was in resubmission form and changed the path from url
      storage.remove.resubmissionToken()
    } else if (this.resubmissionTokenHasError) { // if there was error from resubmission token
      this.setErrorWithResubmissionToken(false)
      const errorOptions = {
        confirmButtonText: this.$i18n.t('swal.ok-button')
      }
      errorPrompt(this.$i18n.t('swal.login.error'), errorOptions)
    }
  }
}
</script>

<style lang='scss'>
@keyframes rotation {
  100% {
    transform:rotate(360deg);
  }
}
.home-page {
  // background-image: url('~@/assets/login.png');
  // background-repeat: no-repeat;
  // background-position: 95% 25vh;
  // @media screen and (max-width: 575px) {
  //   background-image: none;
  // }
  &__form {
    margin-top:6vh;
    word-wrap:break-word;
    line-height: 26px;
    @media screen and (max-width: 768px) {
      text-align: justify;
    }
  }
  &__button {
    &-loading {
      display: inline-block;
      width: 30px;
      vertical-align: middle;
      height: 30px;
      border: 3px solid;
      border-color: transparentize(#ffffff, 0.6) transparentize(#ffffff, 0.6) transparentize(#ffffff, 0.6) #ffffff;
      border-radius: 50%;
      animation: rotation .7s linear infinite;
    }
  }
  &__title{
    font-size: 22px;
  }
  .help-guide {
    margin-top:2vh;
  }
}
</style>
